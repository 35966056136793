/* Colors Neuhaus */
/* Fonts Neuhaus */
.carousel .carousel-control {
  position: absolute;
  z-index: 0;
  width: 30px;
  height: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: calc(50% - 20px); }
  @media (min-width: 769px) {
    .carousel .carousel-control {
      width: 40px;
      height: 40px; } }
  .carousel .carousel-control, .carousel .carousel-control.disabled {
    opacity: 1; }
  .carousel .carousel-control, .carousel .carousel-control:hover {
    padding: 0; }

.carousel .pd-carousel-indicators {
  position: static; }

.carousel-inner {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1rem; }
  @media (min-width: 769px) {
    .carousel-inner {
      padding-bottom: 2rem; } }

.carousel .glider img {
  opacity: 0; }

.carousel .glider .glider-track img {
  opacity: 1; }

.pd-carousel-container {
  margin: 0 auto;
  width: calc(100% - 80px); }
  .pd-carousel-container .carousel-control-prev {
    left: -40px; }
  .pd-carousel-container .carousel-control-next {
    right: -40px; }
